import React, { useState } from "react";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap');
  body { 
    margin: 0; 
  }
`;

const Container = styled.div`
  background-color: #121212;
  color: #eee;
  font-family: "Source Code Pro", monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-height: 100vh;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  padding: 0 2rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  text-shadow: 0 0 10px #333;
  cursor: pointer;
  font-family: "Playfair Display", serif;
  margin: 2rem 0;
  text-transform: lowercase;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  text-align: justify;
  text-align-last: center;

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-family: "Playfair Display", serif;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

const DropdownContainer = styled.div`
  margin-bottom: 2rem;
`;

const Dropdown = styled.select`
  background-color: #222;
  color: #eee;
  border: 1px solid #eee;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-family: "Source Code Pro", monospace;
  font-size: 1rem;
  cursor: pointer;
`;

const HiddenMessage = styled.span`
  visibility: hidden;
  transition: visibility 0.3s;
  position: absolute;
  font-size: 1rem;
  color: #f00;
  font-family: "Playfair Display", serif;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

// --- Short Story Component ---

const ShortStory = ({ title, content }) => (
  <Container>
    <Content>
      <h2>{title}</h2>
      {content.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </Content>
  </Container>
);


// --- Secret Page Content ---

const SecretPage = () => (
  <Container>
    <Content>
      <h2>the hidden chamber</h2>
      <p>
        You have found the hidden chamber. Within these walls, secrets whisper and
        shadows dance. The air is thick with the weight of untold stories, the
        echoes of lives lived and lost.
      </p>
      <p>
        Here, in the heart of darkness, you are invited to confront your deepest
        fears, to embrace the impermanence of existence, to find beauty in the
        fleeting moments that make up a life.
      </p>
      <p>
        Listen closely, and you may hear the whispers of the departed, the
        murmurs of forgotten dreams, the echoes of laughter and tears. For within
        these walls, the past, present, and future converge, and the veil
        between worlds grows thin.
      </p>
      {/* Add more content to the secret page as desired */}
    </Content>
  </Container>
);

const App = () => {
  const [showTitleSecret, setShowTitleSecret] = useState(false);
  const [selectedStory, setSelectedStory] = useState("");

  const handleTitleClick = () => {
    setShowTitleSecret(!showTitleSecret);
  };

  const handleStoryChange = (event) => {
    setSelectedStory(event.target.value);
  };

  const stories = [
    {
      title: "the whispering walls",
      content: [
        "The abandoned asylum loomed against the storm-ridden sky, a monolith of forgotten suffering. Rain lashed against the barred windows, a percussive rhythm to the rising dread in my chest. They said the walls whispered here, voices of the tormented souls trapped within. I, a skeptic, had come to disprove the myth.",
        "The rusted iron gates groaned open, revealing a courtyard choked with weeds and decay. A chill wind whipped through the broken windows, carrying with it the scent of mildew and something... else. A cloying sweetness that clung to the back of my throat, a taste of something ancient and wrong.",
        "Inside, the darkness was absolute, broken only by the occasional flash of lightning. The air hung heavy, thick with the weight of untold suffering. As I ventured deeper, the whispers began, faint at first, then growing louder, a chorus of voices vying for my attention.",
        "They spoke of unimaginable horrors, of experiments gone wrong, of minds shattered and souls consumed. They pleaded for release, for an end to their eternal torment. Fear, cold and paralyzing, gripped me. I wanted to flee, to escape the suffocating darkness and the chilling whispers.",
        "But then, a realization struck me, a horrifying truth that eclipsed even the terror of the asylum. The whispers were not coming from the walls. They were coming from within my own mind. The darkness, the fear, the madness... it was all mine.",
        "There were no ghosts, no tormented souls. Only the echo chamber of my own mortality, the terrifying realization that the only true horror lay in the inevitable oblivion that awaited us all.",
      ],
    },
    {
      title: "the clockwork heart",
      content: [
        "In the city of gears and steam, where automatons walked the cobbled streets and the air hummed with the rhythm of intricate machinery, I found myself drawn to a peculiar shop, its window display showcasing a heart crafted from gleaming brass and intricate clockwork.",
        "Intrigued, I stepped inside, the scent of oil and metal filling my nostrils. The shopkeeper, a man with eyes like polished silver, greeted me with a knowing smile. 'Looking for something to mend a broken heart?' he asked, his voice a low hum that resonated with the ticking of the clockwork heart.",
        "I confessed my fear of death, the constant awareness of my own mortality casting a shadow over my days. The shopkeeper nodded, his gaze piercing. 'Death is the ultimate clockwork mechanism,' he said, 'a precise and inevitable winding down. But within that mechanism lies the beauty of life's fleeting moments.'",
        "He placed the clockwork heart in my hands, its intricate gears whirring and clicking. 'Embrace the rhythm of your own mortality,' he urged, 'and find the beauty in each fleeting tick of the clock.'",
        "As I left the shop, the clockwork heart beating in unison with my own, I realized that death was not an ending, but a catalyst. It was the knowledge of our finite existence that gave life its urgency, its preciousness, its beauty.",
      ],
    },
    {
      title: "the last painting",
      content: [
        "The artist stood before his canvas, his brush poised, his eyes filled with a desperate intensity. He was dying, his body ravaged by disease, but his spirit burned brighter than ever. This painting, his last, would be his final testament, his defiance against the encroaching darkness.",
        "With each stroke, he poured his soul onto the canvas, his fear, his anger, his acceptance, his love for life. Colors swirled and clashed, creating a symphony of emotions, a reflection of the human experience in all its complexity.",
        "As his strength waned, his brushstrokes grew bolder, more frantic. He was racing against time, determined to capture the essence of his existence before it slipped away. The painting became a mirror to his soul, a reflection of his struggle with mortality, his acceptance of the inevitable, and his celebration of the life he had lived.",
        "When the last stroke was made, the artist collapsed, his life force ebbing away. But on the canvas, his spirit lived on, a testament to the power of art to transcend death, to capture the fleeting beauty of existence, and to inspire those who remain.",
      ],
    },
    {
      title: "the dream weaver",
      content: [
        "In the ethereal realm of dreams, where reality blurs and the impossible takes shape, I met the Dream Weaver, a being of pure light and shadow, who wove tapestries of forgotten memories and unlived possibilities.",
        "He showed me dreams of lives unlived, of paths not taken, of choices made and those left unexplored. He showed me the beauty of impermanence, the delicate balance between life and death, the intricate dance of existence.",
        "In the tapestry of my own life, he revealed the threads of fear and doubt, the knots of regret and unfulfilled desires. But he also showed me the shimmering threads of love and joy, the vibrant hues of courage and compassion.",
        "The Dream Weaver taught me that death was not an ending, but a transition, a weaving of our essence into the grand tapestry of existence. He showed me that the fear of death could be a catalyst for living a more meaningful life, a life filled with purpose, connection, and love.",
      ],
    },
    {
      title: "the shadow in the mirror",
      content: [
        "In the quiet solitude of my room, as the twilight cast long shadows across the walls, I gazed into the mirror, my reflection staring back with an unsettling intensity. But it wasn't my own reflection that captured my attention, but the shadow lurking behind it, a dark silhouette that mimicked my every move.",
        "At first, I dismissed it as a trick of the light, a figment of my imagination. But as the days passed, the shadow grew more pronounced, more defined, its presence a constant reminder of something lurking beneath the surface.",
        "One night, as I stared into the mirror, the shadow stepped forward, separating itself from my reflection. It spoke in a voice that was both familiar and alien, a voice that echoed the deepest fears and anxieties within me.",
        "'I am the shadow of your mortality,' it whispered, its voice a chilling caress. 'I am the reminder of your inevitable end, the embodiment of your deepest fears.'",
        "Terror gripped me, but then, a defiant spark ignited within. I would not be consumed by fear. I would not let the shadow dictate my life. I would confront my mortality, embrace my finite existence, and live each moment with intention and purpose.",
        "And as I faced the shadow in the mirror, I realized that it was not a harbinger of doom, but a catalyst for change, a reminder to live fully and authentically in the face of death.",
      ],
    },
    {
      title: "the garden of eternal night",
      content: [
        "Hidden within the sprawling metropolis, a secret garden thrived under the perpetual cloak of night. Moonlight filtered through the dense canopy of ancient trees, casting an ethereal glow upon the exotic blooms that unfurled their petals in defiance of the sun's absence.",
        "I stumbled upon this sanctuary by chance, drawn by an inexplicable curiosity that led me down a labyrinthine path, away from the city's glaring lights and into the embrace of shadows. The air hung heavy with the scent of jasmine and damp earth, a symphony of fragrances that lulled my senses.",
        "As I wandered deeper into the garden's embrace, I encountered a woman with eyes as dark as the night sky, her gaze holding the wisdom of centuries. She spoke of the garden's origins, a place where life and death danced in a delicate balance, where the ephemeral beauty of existence was magnified by the constant presence of its end.",
        "She showed me the cycle of life and death unfolding before my eyes, the wilting blossoms giving way to new growth, the fallen leaves nourishing the soil that would birth future blooms. In this garden of perpetual twilight, I learned that death was not an ending, but a transformation, a necessary part of the eternal dance of existence.",
      ],
    },
    {
      title: "the dance of the dead",
      content: [
        "In a forgotten corner of the world, where ancient traditions intertwined with the whispers of the departed, I witnessed a ritual unlike any other, a dance that celebrated the delicate balance between life and death.",
        "Under the pale glow of the moon, villagers gathered around a bonfire, their faces painted with intricate patterns that mirrored the skeletal figures dancing in the flames. The rhythmic beat of drums echoed through the night, a primal pulse that resonated with the heartbeat of the earth.",
        "As the dance intensified, the boundary between the living and the dead blurred, the spirits of ancestors joining the revelry, their ethereal forms swirling and twirling among the villagers. It was a celebration of life's impermanence, a reminder that death was not an ending, but a transition, a return to the source from which all life emerged.",
        "In the midst of the dance, I felt a sense of profound connection, a oneness with the ancestors, the living, and the earth itself. The fear of death dissipated, replaced by a deep appreciation for the cyclical nature of existence, the eternal dance of life and death that connected us all.",
      ],
    },
    {
      title: "the poet and the raven",
      content: [
        "In a secluded cottage nestled amidst a whispering forest, a solitary poet sought solace from the world's clamor, his only companion a raven with feathers as dark as the ink that stained his parchment.",
        "The poet, haunted by the specter of death, poured his anxieties and fears into his verses, his words weaving tales of mortality and the ephemeral nature of existence. The raven, a silent observer, listened intently, its obsidian eyes reflecting the poet's tormented soul.",
        "One night, as the poet wrestled with his darkest fears, the raven perched upon his writing desk, its presence a comforting weight in the silence. The poet, inspired by the bird's stoic resilience, began to pen a new verse, a poem that celebrated the beauty of life's fleeting moments, the preciousness of each breath, the profound connection between all living beings.",
        "The raven, as if understanding the shift in the poet's perspective, let out a raucous caw, a sound that echoed through the silent cottage, a celebration of life's resilience in the face of death's inevitability.",
      ],
    },
    {
      title: "the astronaut and the void",
      content: [
        "Adrift in the vast expanse of space, an astronaut gazed upon the Earth, a fragile blue sphere suspended in the infinite darkness. The silence of the cosmos pressed against the windows of the spacecraft, a stark reminder of the insignificance of human existence in the face of the universe's immensity.",
        "The astronaut, overwhelmed by a sense of cosmic loneliness, contemplated the fragility of life, the fleeting nature of time, the inevitable march towards oblivion. But as he gazed upon the swirling galaxies and distant stars, a sense of awe and wonder washed over him.",
        "In the face of the void, the astronaut found a newfound appreciation for the preciousness of life, the intricate beauty of the Earth, the interconnectedness of all beings. The fear of death, once a suffocating presence, transformed into a catalyst for living a more meaningful life, a life dedicated to exploration, discovery, and the pursuit of knowledge.",
      ],
    },
    {
      title: "the philosopher and the graveyard",
      content: [
        "In the quiet solitude of a moonlit graveyard, a philosopher wandered among the tombstones, contemplating the mysteries of life and death. The silence of the night was broken only by the rustling of leaves and the distant hoot of an owl.",
        "He paused before a weathered headstone, its inscription barely visible under the pale moonlight. The name etched upon it was that of a renowned artist, a man who had captured the beauty of the world in his paintings, a man who had lived a life filled with passion and creativity.",
        "The philosopher pondered the artist's legacy, the mark he had left upon the world, the beauty he had created that would endure long after his physical form had turned to dust. He realized that death was not an ending, but a transformation, a transition from one state of being to another.",
        "The fear of death, he mused, stemmed from our attachment to the physical, the tangible, the known. But true immortality lay not in the preservation of the flesh, but in the enduring impact we have upon the world, the love we share, the beauty we create, the knowledge we impart.",
        "As the first rays of dawn painted the horizon, the philosopher left the graveyard, his heart filled with a newfound appreciation for the preciousness of life and the enduring power of the human spirit.",
      ],
    },
    {
      title: "the musician and the melody",
      content: [
        "In a dimly lit tavern, filled with the raucous laughter of patrons and the clinking of tankards, a solitary musician sat hunched over his instrument, his fingers dancing across the strings, weaving a melody that spoke of life, loss, and the bittersweet beauty of existence.",
        "The notes flowed from his fingertips, each one a story, each chord a memory, each melody a reflection of the human experience. The music spoke of love found and lost, of dreams pursued and abandoned, of joys celebrated and sorrows endured.",
        "As the night deepened, the tavern's clamor faded, leaving only the musician and his music. The melody grew more intense, more poignant, a lament for the fleeting nature of time, a tribute to the ephemeral beauty of life.",
        "And in that moment, as the final notes echoed through the silent tavern, the musician realized that death was not an ending, but a transformation, a transition from the physical to the ethereal, from the realm of the seen to the realm of the unseen.",
        "His music, he knew, would live on, a testament to his existence, a reminder of the beauty he had created, a source of solace and inspiration for those who remained.",
      ],
    },
    {
      title: "the writer and the blank page",
      content: [
        "A writer sat before a blank page, his mind awhirl with thoughts and emotions, his fingers itching to translate the chaos within into words that would resonate with others. He sought to capture the essence of life, the fleeting beauty of existence, the profound impact of death.",
        "He wrote of love and loss, of joy and sorrow, of hope and despair, of the myriad experiences that shaped the human journey. His words flowed onto the page, weaving tales of triumphs and tragedies, of dreams realized and those left unfulfilled.",
        "As he wrote, he confronted his own mortality, his own fear of the inevitable end. But in the act of writing, he found a sense of purpose, a way to transcend the limitations of his physical existence.",
        "His words, he knew, would outlive him, carrying his thoughts and emotions into the future, connecting him to generations yet unborn. In the face of death, he found immortality in the power of his words to inspire, to console, to provoke, to endure.",
      ],
    },
    {
      title: "the sculptor and the stone",
      content: [
        "In a sun-drenched studio, a sculptor chipped away at a block of marble, his chisel guided by a vision that transcended the physical form. He sought to capture the essence of life, the fleeting beauty of the human form, the enduring strength of the human spirit.",
        "With each strike of the chisel, he revealed the hidden beauty within the stone, transforming the cold, lifeless block into a figure that pulsed with life, with emotion, with the very essence of human existence.",
        "As he worked, he contemplated the impermanence of life, the inevitable decay of the physical form. But in the act of creation, he found a way to defy death, to capture a moment in time, to preserve the beauty of the human spirit for eternity.",
        "The sculpture, he knew, would outlast him, a testament to his artistry, a celebration of life's fleeting beauty, a reminder of the enduring power of the human spirit to create, to inspire, to transcend the limitations of time and space.",
      ],
    },
    {
      title: "the wanderer and the mountain",
      content: [
        "A lone wanderer traversed a rugged mountain path, his gaze fixed upon the towering peak that pierced the clouds. The ascent was arduous, the path treacherous, but the wanderer pressed on, driven by an insatiable curiosity and a yearning for the unknown.",
        "As he climbed higher, the world below seemed to shrink, its worries and concerns fading into insignificance. The air grew thinner, the silence deeper, the landscape more austere.",
        "The wanderer felt a profound connection to the mountain, its ancient wisdom seeping into his soul. He realized that the journey was not just about reaching the summit, but about the transformation that occurred along the way.",
        "The mountain, he understood, was a metaphor for life itself, its peaks and valleys representing the highs and lows of human existence. The climb was a struggle, but the rewards were immeasurable – the breathtaking views, the sense of accomplishment, the profound connection to nature.",
        "And as he finally stood upon the summit, the world spread out before him like an endless tapestry, the wanderer felt a sense of peace and acceptance, a deep understanding of his place in the grand scheme of things.",
      ],
    },
    {
      title: "the cartographer's atlas",
      content: [
        "Elias, the cartographer, lived a life immersed in lines and contours, his world a tapestry of maps and charts. He charted the sprawling city, its hidden alleyways and forgotten corners, with an obsessive precision, each meticulous stroke a defense against the encroaching chaos of the unknown. Yet, the one territory he couldn't map was the one that haunted him most: death.",
        "One day, amidst a forgotten stack of ancient manuscripts, he discovered a curious volume bound in worn leather. Within its brittle pages lay a map unlike any he'd ever seen - a map of the afterlife, intricate pathways leading to realms beyond comprehension. The map pulsed with an eerie luminescence, its lines shifting and swirling like a living entity.",
        "Drawn by an irresistible curiosity, Elias traced the pathways with his fingers, his touch triggering a surge of energy that pulled him into the very map itself. He found himself in a desolate landscape, a barren expanse stretching towards an impossibly distant horizon. The air was thick with a silence that pressed against his eardrums, a silence so profound it was almost a sound in itself.",
        "Fear gripped him, a raw, primal fear he'd never known before. He was alone, utterly alone, in a world devoid of life, of color, of sound. The map, his only guide, offered no solace, its pathways twisting and turning in a maddening maze.",
        "Days turned into an eternity as Elias wandered the desolate landscape. The map, once a source of fascination, became a cruel mockery, its intricate pathways leading him in endless circles. Despair gnawed at him, the stark reality of his mortality a crushing weight upon his soul.",
        "Then, one night, as he lay shivering under the cold, indifferent stars, a voice whispered his name. It was a voice devoid of form, a whisper that seemed to emanate from the very air itself. 'Elias,' it called, 'why do you fear the silence?'",
        "He looked around, searching for the source of the voice, but found only the vast emptiness. 'Because it is the sound of oblivion,' he replied, his voice a dry rasp.",
        "The voice chuckled, a sound like dry leaves skittering across a barren plain. 'Oblivion is but a canvas,' it said, 'upon which you paint your own eternity.'",
        "Elias pondered these words, their meaning slowly unfolding within him. He realized that the map, the afterlife, the desolate landscape – they were all reflections of his own fear, his own unwillingness to confront the unknown.",
        "He closed his eyes, took a deep breath, and let go of the map. The moment he did, the landscape shifted, the silence shattered. Colors erupted around him, vibrant and alive. Sounds filled the air, a symphony of whispers and melodies. The desolate expanse transformed into a garden of unimaginable beauty, a tapestry of life and light.",
        "He was no longer alone. Figures emerged from the vibrant landscape, their forms fluid and luminous, their eyes filled with warmth and compassion. They welcomed him, not as a stranger, but as a long-lost friend.",
        "'We're all just trying to manage terror,' one of them said, their voice a gentle chime. 'But true peace lies in embracing the unknown, in letting go of the fear that binds us to the illusion of permanence.'",
        "Elias, his heart overflowing with gratitude and understanding, realized that the afterlife was not a place, but a state of being. It was the acceptance of mortality, the embrace of the unknown, the surrender to the eternal flow of existence.",
        "And as he stood in the heart of that radiant garden, surrounded by beings of light and love, Elias finally understood. The true map was not the one he had found in the ancient volume, but the one he carried within his own soul. It was the map of his own life, a journey of self-discovery, of confronting fear, of finding beauty in the impermanence of existence.",
        "He returned to the world of the living, not through a portal, but through an act of acceptance, a surrender to the flow of life and death. He carried with him the wisdom of the afterlife, the knowledge that death was not an ending, but a transformation, a continuation of the eternal dance of existence.",
        "From that day forward, Elias continued his cartography, but his maps were no longer just about charting the physical world. They were about mapping the human experience, the journey of the soul, the intricate pathways that led to the heart of existence. And in each line, in each contour, he whispered a silent message: 'Embrace the unknown. Let go of fear. Find beauty in the impermanence of life.'"
      ],
    },
    {
      title: "The Uncatalogued",
      content: [
        "Birdie, the archivist, possessed an uncanny ability to navigate the labyrinthine depths of the historical archives, a repository of forgotten documents and faded photographs, each one a fragment of a life lived, a story whispered across time.  She knew every aisle, every shelf, every drawer, as intimately as the lines on her own palm.  Yet, there was a lingering sense of unease that clung to her, a constant awareness of the finite nature of her own existence, a haunting reminder that her own story would one day be relegated to the archives' dusty shelves - perhaps even become one of the uncatalogued, lost to memory.",
        "One day, while examining a newly acquired collection, a box labeled simply 'Miscellanea', she stumbled upon a cryptic document, its faded ink hinting at a hidden conspiracy, a clandestine operation shrouded in mystery. The document spoke of a secret society known as the Chronomasters, who sought to manipulate time itself, to alter the course of history for their own nefarious purposes.",
        "Intrigued and disturbed by the implications, Birdie embarked on a clandestine mission of her own, a quest to infiltrate the Chronomasters' ranks and expose their plot. She delved deeper into the archives, piecing together fragments of information, deciphering coded messages, and following trails of long-forgotten events.  She felt a kinship with these forgotten stories, these uncatalogued lives, and a fierce determination to protect the delicate balance of time that held them all.",
        "Her investigation led her to a hidden chamber within the archives, a vault tucked away behind a movable bookcase, masked by years of dust and neglect. There, she discovered a device of intricate design, a machine that pulsed with temporal energy, its dials and levers promising the power to manipulate the very fabric of time.",
        "Birdie, her heart pounding with a mix of fear and determination, realized the gravity of the situation. The Chronomasters, driven by their obsession with controlling time, threatened to disrupt the delicate balance of history, to unravel the tapestry of existence.  The uncatalogued lives she cherished, the stories whispered across time, could be erased with a flick of a dial.",
        "In a daring gambit, Birdie decided to sabotage the Chronomasters' device, to disrupt their operation and preserve the integrity of time's flow. She infiltrated their ranks, using her knowledge of the archives and her uncanny ability to blend into the shadows, becoming another forgotten face amongst the forgotten documents.",
        "With meticulous precision, she manipulated the device's intricate mechanisms, introducing subtle alterations that would disrupt its temporal harmony. It was a delicate operation, a high-stakes game of chess against time itself, where one wrong move could have catastrophic consequences.  But Birdie, the archivist, the guardian of the uncatalogued, was not afraid to make her move.",
        "As the Chronomasters initiated their operation, Birdie watched from the shadows, her heart pounding in her chest. The device whirred and pulsed, its temporal energy reaching a crescendo. But just as the Chronomasters were about to achieve their goal, Birdie's sabotage took effect.",
        "The device malfunctioned, its temporal energy erupting in a chaotic burst, disrupting the Chronomasters' plans and sending shockwaves through the corridors of time. The archives trembled, the very fabric of history seemed to warp and distort, but Birdie held her ground, her resolve unwavering.",
        "In the aftermath of the chaotic disruption, the Chronomasters' plot was foiled, their device rendered inert. Birdie, her mission accomplished, emerged from the shadows, her heart filled with a bittersweet sense of triumph and loss.",
        "She had saved the integrity of time, but in doing so, she had glimpsed the fragility of existence, the delicate balance that held the universe in harmony. The experience had deepened her understanding of mortality, reminding her that even the grandest narratives eventually reach their end.",
        "Birdie returned to her work as an archivist, her perspective forever changed. She continued to safeguard the stories of the past, but with a renewed appreciation for the preciousness of time, the ephemeral beauty of life, and the importance of preserving the delicate balance of existence, especially for those lost and uncatalogued."
      ]
    }
  ];

  return (
    <>
      <GlobalStyle />
      <Container>
        <Header>
          <Title onClick={handleTitleClick}>
            managing terror
            {showTitleSecret && (
              <HiddenMessage
                style={{
                  position: "relative",
                  top: "-2rem",
                  fontSize: "1rem",
                  left: "0",
                  transform: "translateX(0)",
                }}
              >
                Death's inevitability.
              </HiddenMessage>
            )}
          </Title>
        </Header>

        <DropdownContainer>
          <Dropdown value={selectedStory} onChange={handleStoryChange}>
            <option value="">Select a story...</option>
            {stories.map((story, index) => (
              <option key={index} value={story.title}>
                {story.title}
              </option>
            ))}
            <option value="secret-page">the hidden chamber</option>
          </Dropdown>
        </DropdownContainer>

        {/* Render the selected story or secret page */}
        {stories.map((story, index) => (
          <React.Fragment key={index}>
            {selectedStory === story.title && (
              <ShortStory title={story.title} content={story.content} />
            )}
          </React.Fragment>
        ))}
        {selectedStory === "secret-page" && <SecretPage />}
      </Container>
    </>
  );
};

export default App;